.headerbackground {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: fit;
  width: 100%;
  height: 100%;
  background-color: #000;
  background-image: url("https://ldssite.b-cdn.net/images/background.jpg");
}

.sectionbackground {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: fit;
  width: 100%;
}

.sectioncolored {
  width: 100%;
  height: 100%;
  background-color: #000000e6;
}

.loginbackground {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: fit;
  width: 100%;
  height: 100%;
  background-color: #000;
  background-image: url("https://ldssite.b-cdn.net/images/background.jpg");
}

.fullheightbody {
  height: 100%;
}

.root, html, body {
  height: 100%;
  margin: 0;
}

.bodybg {
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("https://ldssite.b-cdn.net/images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
}

.better-form-control {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: .35rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

.better-form-group {
  margin-bottom: 1rem;
}

.terms-iframe {
  width: 100%;
  height: 50%;
  min-height: 300px;
}

.float-right {
  float: right;
}

.inline-float {
  float: left;
  display: inline;
}

.btn-twitch {
  color: #fff !important;
  background-color: #9146ff !important;
  border-color: #000 !important;
}

.bold {
  font-weight: bold !important;
}

.hero_trailer {
  height: 100%;
  width: 100%;
  object-fit: fill;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.hero_trailer_fix {
  z-index: 3;
  position: relative;
}

.trailer_video_container {
  min-height: 100px;
}

.hero_gradient {
  height: 100%;
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.social-icon {
  width: 50px !important;
}

.profile-side-card {
  height: calc(100vh - 300px) !important;
}

.grid-cards {
  grid-template-columns: repeat(auto-fill, minmax(346px, 1fr));
  grid-auto-rows: 470px 346px;
  gap: 15px;
  display: grid;
  overflow-x: auto;
}

.game-title-card-bg {
  width: 100%;
  position: absolute !important;
}

.game-title-card {
  filter: drop-shadow(4px 4px 2px #888);
  width: 346px !important;
  height: 460px !important;
}

.game-title-ribbon {
  z-index: inherit;
  left: -23px;
}

.item-grid-cards {
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 380px 346px;
  gap: 15px;
  display: grid;
  overflow-x: auto;
}

.game-item-card-bg {
  width: 100%;
  position: absolute !important;
}

.game-item-card {
  filter: drop-shadow(4px 4px 2px #888);
  width: 346px !important;
  height: 346px !important;
}

.game-item-ribbon {
  z-index: inherit;
  left: -15px;
}

.card-shimmer-effect {
  background-repeat: no-repeat;
  animation: 5.5s infinite shimmer;
  -webkit-mask: linear-gradient(-45deg, #000 10%, #05050599, #000 90%) 100% / 400% 100%;
}

.card-shimmer-effect:hover {
  animation-name: shine;
}

@keyframes shine {
  50% {
    -webkit-mask-position: 0;
  }

  100% {
    -webkit-mask-position: 100%;
  }
}

.clickable {
  cursor: pointer;
}

.item-grid-cards-sm {
  grid-template-columns: repeat(auto-fill, minmax(173px, 1fr));
  grid-auto-rows: 190px 173px;
  gap: 15px;
  display: grid;
  overflow-x: auto;
}

.game-item-card-sm {
  filter: drop-shadow(4px 4px 2px #888);
  width: 173px !important;
  height: 173px !important;
}

.product-grid-cards {
  grid-template-columns: repeat(auto-fill, minmax(346px, 1fr));
  grid-auto-rows: 346px 346px;
  gap: 15px;
  display: grid;
  overflow-x: auto;
}

.store-bg {
  background-color: #000;
  background-image: url("https://ldssite.b-cdn.net/images/store_background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.store-body {
  min-height: 100vh;
}

.item_title_price {
  z-index: 3;
  height: 40px;
  background-color: #000000c7;
  font-size: 18px;
  position: relative;
  top: -5px;
}

.text-right {
  text-align: center !important;
}

.discount {
  background-color: #00ff4088;
}

.storeTitleCell {
  height: 40px;
}

.pricecell-sale {
  font-size: 12px !important;
}

.pricecell {
  color: #fff;
  background-color: #0084ff66;
}

.namecell {
  color: #fff;
  width: 100%;
}

.item_title_price_sm {
  z-index: 3;
  height: 20px;
  background-color: #000000c7;
  font-size: 12px;
  position: relative;
  top: -5px;
}

.storeTitleCellSm {
  height: 20px;
}

.apocalypse-token-icon {
  width: 16px;
  content: url("https://ldssite.b-cdn.net/images/token_icon.png");
}

.free-token-icon {
  width: 16px;
  content: url("https://ldssite.b-cdn.net/images/free_token_icon.png");
}

.apocalypse-token-icon-md {
  width: 32px;
  content: url("https://ldssite.b-cdn.net/images/token_icon.png");
}

.free-token-icon-md {
  width: 32px;
  content: url("https://ldssite.b-cdn.net/images/free_token_icon.png");
}

.token-price {
  min-width: 60px;
}

.token_widget {
  height: 40px;
  position: absolute;
  right: 0;
}

.token-cell {
  max-width: 130px !important;
}

.token-cell-value {
  font-size: 20px;
  position: relative;
  top: 4px;
  left: 5px;
}

.token-widget-user-name {
  font-size: 20px;
  position: relative;
  top: 4px;
  text-align: right !important;
}

.game-overview-header-image {
  width: 100%;
  z-index: -1;
  height: 200px;
  position: absolute;
}

.game-overview-cover-image {
  width: 100%;
}

.product-overview-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.product-page-spacer {
  height: 100px;
}

.bg-store-cover-card {
  background: #4949497a !important;
}

.carousel-thumbnail {
  width: 150px;
}

.carousel-thumbnail-row, .carousel-thumbnail-outer {
  height: 100px;
  overflow-x: auto;
  overflow-y: hidden;
}

.purchase_card_title_price {
  z-index: 3;
  height: 20px;
  font-size: 24px;
  position: relative;
  top: -5px;
}

.play-now-message {
  font-size: 24px;
}

.product-page-image {
  max-height: 500px;
}

.nowrap {
  white-space: nowrap;
  flex-direction: row;
  display: flex;
  overflow: hidden;
}

.price-shift {
  position: relative;
  top: -2px;
}

.price-row-shift {
  position: relative;
  top: 1px;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-400 {
  max-width: 400px;
}

/*# sourceMappingURL=index.d7f19d3d.css.map */
