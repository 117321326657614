.headerbackground {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: fit;
    width:100%;
    height:100%;
    background-image: url("https://ldssite.b-cdn.net/images/background.jpg");
    background-color: #000;
}
.sectionbackground {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: fit;
    width:100%;
    
}
.sectioncolored {
    background-color: rgba(0, 0, 0, .9);
    width: 100%;
    height: 100%;
}
.loginbackground {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: fit;
    width:100%;
    height:100%;
    background-image: url("https://ldssite.b-cdn.net/images/background.jpg");
    background-color: #000;
}
.fullheightbody {

    height:100%;
}
.root {
    margin: 0;
    height:100%;
}
html, body {
    margin: 0;
    height: 100%;   
}
.bodybg{
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url("https://ldssite.b-cdn.net/images/background.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    background-attachment: fixed;
    z-index: -1;
}
.better-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.better-form-group {
  margin-bottom: 1rem;
}
.terms-iframe{
    width:100%;
    height:50%;
    min-height:300px;
}

.float-right {
    float: right;
}

.inline-float {
    display:inline;
    float:left;
}

.btn-twitch {
  color: #fff!important;
  background-color: #9146FF!important;
  border-color: #000!important;
}

.bold {
  font-weight: bold !important;
}
.hero_trailer{
    position:absolute;
    top:0px;
    left:0px;
    height: 100%;
    width: 100%;
    object-fit: fill;
    z-index: 0;
}
.hero_trailer_fix{
    position:relative;

    z-index: 3;
}
.trailer_video_container{
    min-height: 100px;
}
.hero_gradient{
    position:absolute;
    top:0px;
    left:0px;
    height: 100%;
    width: 100%;
    z-index: 2;
}
.social-icon{
    width: 50px !important;
    
}
.profile-side-card{
    height: calc(100vh - 300px) !important;
}

.grid-cards{
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(346px, 1fr) );
    gap: 15px;
    grid-auto-rows: 470px 346px;
    overflow-x: auto;
}
.game-title-card-bg{
    position: absolute !important;
    width: 100%;
}

.game-title-card{
    width: 346px !important;
    height: 460px !important;
    filter: drop-shadow(4px 4px 2px #888888);
}
.game-title-ribbon{
    z-index: inherit;
    left: -23px;
}
.item-grid-cards{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    grid-auto-rows: 380px 346px;
    overflow-x: auto;
}
.game-item-card-bg{
    position: absolute !important;
    width: 100%;

}
.game-item-card{
    width: 346px !important;
    height: 346px !important;
    filter: drop-shadow(4px 4px 2px #888888);
}
.game-item-ribbon{
    z-index: inherit;
    left: -15px;
}
.card-shimmer-effect{
    -webkit-mask:linear-gradient(-45deg,#000 10%,rgba(5, 5, 5, .6),#000 90%) right/400% 100%;
    background-repeat: no-repeat;
    animation: shimmer 5.5s infinite;
}
.card-shimmer-effect:hover {
    animation-name: shine;
  }
  @keyframes shine {
    50% {-webkit-mask-position:left}
    100% {-webkit-mask-position:right}
  }
  .clickable{
    cursor: pointer;
  }
  .item-grid-cards-sm{
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(173px, 1fr) );
    gap: 15px;
    grid-auto-rows: 190px 173px;
    overflow-x: auto;
}
.game-item-card-sm{
    width: 173px !important;
    height: 173px !important;
    filter: drop-shadow(4px 4px 2px #888888);
}
.product-grid-cards{
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(346px, 1fr) );
    gap: 15px;
    grid-auto-rows: 346px 346px;
    overflow-x: auto;
}
.store-bg{
    background-color: #000;
    background-image: url("https://ldssite.b-cdn.net/images/store_background.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

}
.store-header{

}
.store-body{
    min-height: 100vh;
}
.item_title_price{
    z-index: 3;
    height: 40;
    top:-5px;
    position: relative;
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0.781);

}
.text-right{
    text-align: center !important;
}
.discount{
    background-color: rgba(0, 255, 64, 0.534);
}
.storeTitleCell{
    height: 40px;
}
.pricecell-sale{
    font-size: 12px !important;
}
.pricecell{
    background-color: rgba(0, 132, 255,0.4);
     color: #FFF;
}
.namecell{
    color: #FFF;
    width: 100%;
}
.item_title_price_sm{
    z-index: 3;
    height: 20;
    top:-5px;
    position: relative;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.781);
}
.storeTitleCellSm{
    height: 20px;
}
.apocalypse-token-icon{
    width: 16px;
    content: url("https://ldssite.b-cdn.net/images/token_icon.png");
}
.free-token-icon{
    width: 16px;
    content: url("https://ldssite.b-cdn.net/images/free_token_icon.png");
}
.apocalypse-token-icon-md{
    width: 32px;
    content: url("https://ldssite.b-cdn.net/images/token_icon.png");
}
.free-token-icon-md{
    width: 32px;
    content: url("https://ldssite.b-cdn.net/images/free_token_icon.png");
}
.token-price{
    min-width: 60px;
}
.token_widget{
    position: absolute;
    right:0px;
    height: 40px;
}
.token-cell{
    max-width: 130px !important;
}
.token-cell-value{
    position: relative;
    top:4px;
    left: 5px;
    font-size: 20px;
}
.token-widget-user-name{
    font-size: 20px;
    text-align: right !important;
    top:4px;
    position: relative;
}
.game-overview-header-image{
    position: absolute;
    width: 100%;
    z-index: -1;
    height: 200px;
}
.game-overview-cover-image{
    width: 100%;
}
.product-overview-bg{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
.product-page-spacer{
    height: 100px;
}
.bg-store-cover-card{
    background: rgba(73, 73, 73, 0.479) !important;
}
.carousel-thumbnail{
    width:150px;
}

.carousel-thumbnail-row{
    height:100px;
    overflow-y:hidden;
    overflow-x: auto;
}
.carousel-thumbnail-outer{
    height:100px;

    overflow-y:hidden;
    overflow-x: auto;
}
.purchase_card_title_price{
    z-index: 3;
    height: 20;
    top:-5px;
    position: relative;
    font-size: 24px;
}
.play-now-message{
    font-size: 24px;
}
.product-page-image{
    max-height: 500px;
}
.nowrap{
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
}
.price-shift{
    top:-2px;
    position: relative;
}
.price-row-shift{
    top:1px;
    position: relative;
}
.max-width-200{
    max-width: 200px;
}
.max-width-400{
    max-width: 400px;
}